import { useState, useEffect } from "react";
import { Avatar } from "@mui/material";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { appConfig } from "../../config/Config";
import { HttpCustomApi } from "../../interface/custom-api";
import { userState } from "../../interface/MainInterface";
import {
  ChatSetProps,
  CountryType,
  iVisa,
} from "../../interface/VisaInterface";
import { countries } from "../components/TeamList";
import CountrySearch from "../components/TeamSearch";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import LoadingCircle from "../../utils/LoadingCircle";

const visaApi = new HttpCustomApi(
  window.location.hostname === appConfig.prodURL
    ? appConfig.REACT_APP_VISA_API_PROD
    : appConfig.REACT_APP_VISA_API_DEV
);

interface propsType {
  userState: userState;
}

const TestListPage = (props: propsType) => {
  let _maxCount: number = 3;
  let pocid = props.userState.id;

  const { code } = useParams(); // 국가코드


  // 자주 찾는 국가 하드코딩
  const [bestCountryList, setBestCountryList] = useState<any>([
    "테스트",
  ]);
  const [countryClick, setCountryClick] = useState(false);
  const [location, setLocation] = useState("");
  const [dtcountriesinfo, setdtcountriesinfo] = useState<iVisa[]>([]);
  const [searchedCountryList, setSearchedCountryList] = useState<iVisa[]>([]);
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const [isUpdateDetail, setIsUpdateDetail] = useState<boolean>(false);
  const [recentUpdateCounties, setRecentUpdateCounties] = useState<any>([]);
  const [initSearched, setInitSearched] = useState<boolean>(true);
  const [listCnt, setListCnt] = useState(4);
  const [isInitialSet, setIsInitialSet] = useState(false);
  const navigate = useNavigate()


  const setCountrySet = async () => {
    const visainfoparam: any = {
      command: "get_visa_information_proj",
      pk: "entry#team",
      projection: "#id, country, attach_files, range, detail_information",
    };

    const visares = await visaApi.common_request("adminMain", visainfoparam);
    if (visares.code === "200") {
      const searchResult: iVisa[] = visares.response.result_list;
      setdtcountriesinfo(searchResult);
    }
  };

  const searchCountry = (result: any) => {
    if (!result) {
      return; // location null이면 함수 종료
    }
    for (const element of dtcountriesinfo) {
      if (result === element.country) {
        setSearchedCountryList([element]);
      }
    }
    setIsComponentVisible(true);
  };

  const updateSearchCountry = async () => {
    for (const element of dtcountriesinfo) {
      if (location === element.country) {
        setSearchedCountryList([element]);
      }
    }
  };

  const openDetail = (code: string) => {
    navigate(code)
    setIsComponentVisible(false);
  }

  // 자주찾는 국가를 하드코딩으로 변경
  const getBestCountry = async () => {
    const lambda_param: any = {
      command: "get_bestcountry",
      max_count: _maxCount, // 안쓰는 파라미터
    };

    const res = await visaApi.common_request("adminMain", lambda_param);
    if (res.code === "200") {
      const searchResult: ChatSetProps[] = res.response.result_list;
      let source_list: ChatSetProps[] = [];
      let source_list_old: ChatSetProps[] = [];
      for (const source of searchResult) {
        if (source.RANGE.includes("어제") || source.RANGE.includes("오늘")) {
          source_list.push(source);
        } else {
          source_list_old.push(source);
        }
      }
      setBestCountryList(source_list);
    }
  };

  // 최근 변경 데이터 가져오기
  const getRecentUpdateCountries = async () => {

    if (dtcountriesinfo.length > 0) {
      dtcountriesinfo.sort((a, b) => {
        if (a.range && b.range) {
          if (a.range > b.range) return -1;
          if (a.range < b.range) return 1;
        }
        return 0;
      });

      var recent_countries = [];
      var recent_countries_list = [];
      for (let i = 0; i < dtcountriesinfo.length; i++) {
        recent_countries.push(dtcountriesinfo[i]["country"]);
        recent_countries_list.push(dtcountriesinfo[i]);
      }
      setSearchedCountryList(recent_countries_list);
      setRecentUpdateCounties(recent_countries);
      setIsInitialSet(true);
    }
  };

  const btnMore = () => {
    setListCnt(listCnt + 4);
  };


  useEffect(() => {
    if (isUpdateDetail) {
      updateSearchCountry();
      setIsUpdateDetail(false);
    }

    // 최근데이터는 dtcountriesinfo에따라 바로 변경하기
    getRecentUpdateCountries();
    setListCnt(4);
  }, [dtcountriesinfo]);

  useEffect(() => {
    if (initSearched) {
      setSearchedCountryList([recentUpdateCounties]);
      setInitSearched(false);
    }
  }, [recentUpdateCounties]);

  useEffect(() => {
    if (isUpdateDetail) {
      setCountrySet();
    }
  }, [isUpdateDetail]);

  useEffect(() => {
    setCountrySet();
  }, []);

  useEffect(() => {
    if (countryClick) {
      searchCountry(location);
      setIsComponentVisible(true);
      setCountryClick(false);
    }
  }, [countryClick]);

  useEffect(() => {
    setIsComponentVisible(code !== undefined ? false : true)
  }, [code]);

  return (
    <Box>
      <Box id="container">
        {" "}
        {/* GNB가 불필요한 시스템의 경우 class="noneHeader" 추가 */}
        <Box id="contents">
          <Box className="twoSecCont">
            {/* SNB */}
            <Box className="snb">
              <CountrySearch fnSearch={searchCountry} />
              <Box
                className="scroll"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'nowrap',
                  gap: '24px',
                }}
                sx={{
                  paddingRight: '0'
                }}
              >
                <Box className="snbVisaList">
                  <p className="snbTit"></p>
                  <List className="bestNation">
                    {/* {bestCountryList.map((bestCountry: string, index: number) => {
                      const foundCountry = countries.find(
                        (country) => country.label === bestCountry
                      );
                      if (foundCountry) {
                        // TODO : 클릭할때 마다 렌더링되는거 막기
                        return (
                          <li
                            key={index}
                            onClick={() => {
                              setLocation(foundCountry.label); // 선택된 값을 설정
                              setCountryClick(true);
                              window.history.pushState("", "", "/visalist") // 랜더링 없이 url변경
                            }}
                          >
                            <a style={{ cursor: "pointer" }}>
                              <span className="nation">{foundCountry.label}</span>
                            </a>
                          </li>
                        );
                      }
                    })} */}
                  </List>
                </Box>
                <Box className="snbVisaList bottom">
                  <p className="snbTit">최신 데이터 변경</p>
                  <ul className="latestNews">
                    {recentUpdateCounties &&
                      recentUpdateCounties
                        .slice(0, 4)
                        .map((recentCountry: any, index: number) => {
                          const foundCountry = countries.find(
                            (country) => country.label === recentCountry
                          );
                          if (foundCountry) {
                            return (
                              <li
                                key={index}
                                onClick={() => {
                                  setLocation(foundCountry.label); // 선택된 값을 설정
                                  setCountryClick(true);
                                  window.history.pushState("", "", "/visalist") // 랜더링 없이 url변경
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <a>
                                  {/* <img
                                    // src={`https://img.hyundaidreamtour.com/images/aihdt/flag/flag_${foundCountry.code.toLowerCase()}.png`}
                                    alt={foundCountry.label}
                                  /> */}
                                  <span className="nation">
                                    {foundCountry.label}
                                  </span>
                                </a>
                              </li>
                            );
                          }
                        })}
                  </ul>
                </Box>
              </Box>
            </Box>
            {/* // SNB */}

            {isComponentVisible ? (
              <Box className="contBody">
                <Box className="contHeader">
                  <Box className="tit">팀별 데이터 상세정보</Box>
                </Box>
                <Box className="contBox">
                  <Box className="scroll">
                    <ul className="listD">
                      <>
                        {searchedCountryList[0] &&
                          searchedCountryList[0].country &&
                          searchedCountryList
                            .slice(0, listCnt)
                            .map((item, index) => {
                              let country_code = "";
                              let temp = countries.find((country) => country.label === item.country);
                              if (temp) {
                                country_code = temp.code;
                              }
                              return (
                                <li
                                  key={index}
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    openDetail(country_code)
                                  }
                                >
                                  <a className="visaNews">
                                    <Box className="countryInfo">
                                      <p className="nation">
                                        {/* <img
                                          src={`https://img.hyundaidreamtour.com/images/aihdt/flag/flag_${country_code.toLowerCase()}.png`}
                                          alt={item.country}
                                        /> */}
                                        <span>{item.country}</span>
                                      </p>
                                      <p className="date">{item.range?.substring(0, 4)}-{item.range?.substring(4, 6)}-{item.range?.substring(6, 8)} {item.range?.substring(8, 10)}:{item.range?.substring(10, 12)}</p>
                                      <p className="attachNum">
                                        첨부파일{" "}
                                        <i> {item.attach_files.length}</i>
                                      </p>
                                    </Box>
                                    <dl className="preview">
                                      <dt>{item.detail_information}</dt>
                                    </dl>
                                  </a>
                                </li>
                              );
                            })}
                      </>
                    </ul>
                    <a className="btnMore" onClick={btnMore}>
                      더보기
                    </a>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Outlet />
            )}
          </Box>
        </Box>
        {/*// contents */}
      </Box>
      {/*// container */}
      <LoadingCircle loading={!isInitialSet} />
    </Box>
  );
};

export default TestListPage;
