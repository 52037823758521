import { useEffect, useState } from "react";
import { Box, Autocomplete, TextField, Button } from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
// import CountryDdbList from "./CountryDdbList";
import { countries } from "./TeamList";

interface propsType {
  fnSearch: (tlocation: string | null) => void;
}

const CountrySearch = (props: propsType) => {
  const [location, setLocation] = useState<string | null>("");

  useEffect(() => {
    props.fnSearch(location)
  }, [location]);

  return (
    <Box className="searchCont">
      <Autocomplete
        id="highlights-demo"
        options={countries}
        autoHighlight
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.label
        }
        onChange={(event, newValue) => {
          if (typeof newValue === "object" && newValue !== null) {
            setLocation(newValue.label);
          } else if (typeof newValue === "string") {
            setLocation(newValue);
          } else {
            setLocation(null);
          }
        }}
        renderInput={(params) => (
          <TextField
            className="inputSt01"
            {...params}
            value={location}
            type="text"
            placeholder="팀명"
            inputProps={{
              ...params.inputProps,
              style: {
                borderRadius: "8px",
                fontFamily: "none",
                border: "none",
              },
            }}
            sx={{
              width: "100%",
              "& fieldset": {
                top: "0",
                borderRadius: "8px",
                height: "50px",
              },
            }}
          />
        )}
        renderOption={(props, option, { inputValue }) => {
          const matches = match(option.label, inputValue, {
            insideWords: true,
          });
          const parts = parse(option.label, matches);

          return (
            <li {...props}>
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                      color: part.highlight ? "#1D81A5" : "inherit",
                      textDecoration: part.highlight ? "underline" : "none",
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }}
        PaperComponent={({ children }) => (
          <Box className="aiDash">
            <Box className="searchD">
              <Box className="aiSrchDiv">
                <Box
                  className="relatedTerms"
                  sx={{ position: "static !important" }}
                >
                  <Box className="group country">
                    <p className="gTit">팀명</p>
                    <ul className="gList">{children}</ul>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      />
      {/* <CountryDdbList setParentCountries={setDtcountries} /> */}
    </Box>
  );
};

export default CountrySearch;
