import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Avatar, Typography } from "@mui/material";

import { appConfig } from "../../config/Config";
import { HttpCustomApi } from "../../interface/custom-api";
import { iVisa } from "../../interface/VisaInterface";
import AttachFileViewer from "../../utils/attach/AttachFileViewer";
import { userState } from "../../interface/MainInterface";
import { countries } from "../components/TeamList";
import LoadingCircle from "../../utils/LoadingCircle";
import S3RemoveAndUpload from "../../utils/attach/S3RemoveAndUpload";

const visaApi = new HttpCustomApi(
  window.location.hostname === appConfig.prodURL
    ? appConfig.REACT_APP_VISA_API_PROD
    : appConfig.REACT_APP_VISA_API_DEV
);

interface propsType {
  userState: userState;
}



const TestDetail = (props: propsType) => {
  const navigate = useNavigate();
  const { code } = useParams(); // 국가코드
  const country = countries.find(country => country.code === code)
  const [visa, setVisa] = useState<iVisa>();
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [isS3Uploaded, setIsS3Uploaded] = useState(false);
  const [isEditData, setIsEditData] = useState(false);
  const [isInit, setIsInit] = useState(true);


  const textFieldRef = useRef(null);
  const attachFileViewerRef: any = useRef();


  const visaDataEdit = async () => {
    setIsEditData(true);
  };


  // 국가로 ddb가져오기
  const get_visa = async () => {
    setIsLoading(true);
    if (country === undefined) {
      alert("해당 팀을 찾을 수 없습니다.")
      navigate(-1)
      setIsLoading(false)
    }
    const param = {
      command: "get_visa_sk",
      pk: "entry#team",
      sk: country?.label
    }
    const res = await visaApi.common_request("adminMain", param);
    if (res.code === "200") {
      setVisa(res.response.result[0]);

      // 첨부파일은 별도 처리(더미데이터) : type때문
      const transformedFilesS3: File[] = res.response.result[0].attach_files.map((attach_file: any) => {
        const fileExt = attach_file.FILE_NAME.split('.').pop();
        // 파일 객체 생성
        const file = new File(
          [attach_file], // 파일 데이터
          attach_file.FILE_NAME, // 파일 이름
          // attach_file.FILE_SIZE, // 파일 사이즈
          { type: `application/${fileExt}` } // 파일 타입
        );
        Object.defineProperty(file, 'isDummy', {
          value: 'Y',
          writable: false,
          enumerable: true,
          configurable: true
        });
        Object.defineProperty(file, 'dummy_size', {
          value: attach_file.FILE_SIZE,
          writable: false,
          enumerable: true,
          configurable: true
        });
        Object.defineProperty(file, 'dummy_url', {
          value: attach_file.DOWNLOAD_URL,
          writable: false,
          enumerable: true,
          configurable: true
        });
        // File 객체 반환
        return file;
      });
      setFiles(transformedFilesS3);

      // 이게 useEffect가 작동을 안해야돼 
    }
    else if (res.code === "300") {
      console.log(" 300 res.response : ", res.response);
    }
    else if (res.code === "500") {
      console.log(" 500 res.response : ", res.response);
    }
    setIsLoading(false);
  };


  // 파일 드래그 앤 드랍
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    setFiles(prevFiles => [...prevFiles, ...droppedFiles]);
  };

  // 파일 올리기
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const uploadedFiles = Array.from(event.target.files);
      setFiles(prevFiles => [...prevFiles, ...uploadedFiles]);
    }
  };

  // 파일 제거
  const attachFileViewerCallback = (payload: any) => {
    if (payload?.command === "delete") {
      setFiles(prevFiles => prevFiles.filter((file) => file.name !== payload.fileInfo.FILE_NAME));
    }
  };

  // 등록 버튼
  const handleUpload = () => {
    if (!window.confirm("수정 내용을 저장하시겠습니까?")) return;
    //s3부터 업로드
    if (window.location.hostname === appConfig.prodURL) {
      S3RemoveAndUpload(
        files,
        `visa/${country?.label}/`,
        process.env.REACT_APP_S3_BUCKET_NAME_PROD,
        fncUploadComplete
      )
    }
    else {
      S3RemoveAndUpload(
        files,
        `visa/${country?.label}/`,
        process.env.REACT_APP_S3_BUCKET_NAME_DEV,
        fncUploadComplete
      )
    }
  };

  const padZero = (num: number, len: number = 2): string => {
    if (len === 2) { return num < 10 ? `0${num}` : `${num}` }
    else { return num < 10 ? `00${num}` : (num < 100 ? `0${num}` : `${num}`) }
  };


  const visaUploadDdb = async () => {
    const now = new Date();
    const formattedTime = `${now.getFullYear()}${padZero(now.getMonth() + 1)}${padZero(now.getDate())}${padZero(now.getHours())}${padZero(now.getMinutes())}${padZero(now.getSeconds())}${padZero(now.getMilliseconds(), 3)}`;

    const visaparam: any = {
      command: "put_visa_information",
      id: "entry#team",
      country: country?.label,
      editor: props.userState.name,
      detail_information: visa?.detail_information,
      attach_source: visa?.attach_source,
      range: formattedTime,
      attach_files: visa?.attach_files,
      insert_dt: visa?.insert_dt,
    };

    const res = await visaApi.common_request("adminMain", visaparam);
    if (res.code === "200") {
      alert("수정이 완료되었습니다.");
      setIsEditData(false);
    } else {
      window.alert("수정에 실패하였습니다. 다시 한번 시도해주세요.");
      setIsLoading(false)
      return;
    }
    setIsLoading(false)
  };

  // visa 수정
  const handleVisaChange = (key: string, value: string) => {
    setVisa(prevVisa => ({
      ...prevVisa,
      [key]: value
    }))
  }

  function handlePrevClick() {
    if (isEditData) {
      const userConfirmed = window.confirm(
        "수정 사항이 있을 시 모두 초기화 됩니다. 계속하시겠습니까?"
      );
      if (userConfirmed) {
        setIsEditData(false)
        navigate(0)
      } else {
        return;
      }
    } else {
      // TODO : 랜더링 없애기
      navigate(`/testlist`)
    }
  }


  // s3에 첨부파일 업로드 완료시 콜백
  const fncUploadComplete = (result: any) => {
    if (result.status === "ok") {
      setIsS3Uploaded(true)
      handleVisaChange("attach_files", result.fileInfo)
    }
  };




  useEffect(() => {
    get_visa();
    const resizeObserver = new ResizeObserver((entries) => { });
    if (textFieldRef.current) {
      resizeObserver.observe(textFieldRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  // file을 올렸을때 visa 임시추가
  useEffect(() => {
    // if (visa === undefined) return
    if (visa && isInit) {
      setIsInit(false);
      return;
    }
    else {
      const transformedFiles = files.map((file: any) => ({
        attach_range: new Date(file.lastModified).toLocaleDateString(),
        DOWNLOAD_URL: file.isDummy ? file.dummy_url : "",
        FILE_EXT: file.name.split('.').pop(), // 파일 확장자를 추출하여 설정
        FILE_NAME: file.name,
        FILE_PATH: "",
        FILE_SIZE: file.isDummy ? file.dummy_size : file.size,
      }));
      setVisa(prevVisa => ({
        ...prevVisa,
        attach_files: transformedFiles
      }))
    }

  }, [files]);

  useEffect(() => {
    //s3가 업로드된 상태면 - ddb 업데이트
    if (isS3Uploaded) {
      visaUploadDdb();
      setIsS3Uploaded(false);
    }
  }, [isS3Uploaded]);

  return (
    <Box className="contBody">
      <Box className="contHeader">
        <Box className="tit">
          <IconButton
            className="btnPrev"
            style={{ width: "24px" }}
            onClick={() => handlePrevClick()}
          >
            이전
          </IconButton>
          <Box
            className="nation"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Avatar
              src={`https://img.hyundaidreamtour.com/images/aihdt/flag/flag_${country?.code.toLowerCase()}.png`}
              alt={country?.label}
              sx={{ width: 24, height: 24, marginRight: "3px" }}
            />
            <span>{country?.label}</span>
          </Box>
          <p className="date">{visa?.range?.substring(0, 4)}-{visa?.range?.substring(4, 6)}-{visa?.range?.substring(6, 8)} {visa?.range?.substring(8, 10)}:{visa?.range?.substring(10, 12)}</p>
        </Box>
        <Box className="rightbtnArea">
          {/* 클릭시 레이어 팝업, 작업예정 */}
          {/* <a className="btnEmail">이메일 전송</a> */}
          <Button
            className="btnLineGray"
            variant="outlined"
            sx={{
              borderColor: "#B4B4B4",
              borderRadius: "8px",
              color: "#000000",
            }}
            onClick={isEditData ? handleUpload : visaDataEdit}
          >
            {isEditData ? "완료" : "수정"}
          </Button>
          {/* <a className="btnLineGray">삭제</a> */}
        </Box>
      </Box>
      <Box className="contBox">
        <Box className="scroll">
          <Box className="readD">
            <Typography sx={{ paddingTop: "20px" }} fontWeight={500}>
              데이터 내용
            </Typography>
            <TextField
              id="detail_information"
              name="detail_information"
              ref={textFieldRef}
              multiline
              value={visa?.detail_information}
              onChange={(e) => isEditData ? handleVisaChange(e.target.id, e.target.value) : undefined}
              sx={{
                width: "100%",
                paddingLeft: "10px",
                "& fieldset": isEditData
                  ? { top: "0" }
                  : { border: "0", top: "0" },
                "& .MuiOutlinedInput-root": { padding: "0 !important" },
              }}
              inputProps={{
                style: {
                  width: "100%",
                  paddingLeft: "4px",
                  border: "0px",
                  WebkitAppearance: "none",
                  WebkitBoxShadow: "none",
                  MozBoxShadow: "none",
                  boxShadow: "none",
                },
              }}
            />
          </Box>
        </Box>
        <LoadingCircle loading={isLoading} />
      </Box>
      <Box
        onDrop={isEditData ? handleDrop : undefined}
        onDragOver={isEditData ? (event) => event.preventDefault() : undefined}
        sx={{ margin: "12px" }} >
        {(window.location.hostname === appConfig.prodURL) ? (
          <AttachFileViewer
            ref={attachFileViewerRef}
            rootBucket={process.env.REACT_APP_S3_BUCKET_NAME_PROD}
            files={visa?.attach_files}
            canDelete={isEditData ? true : false}
            isBox={true}
            callback={attachFileViewerCallback}
          />
        ) : (
          <AttachFileViewer
            ref={attachFileViewerRef}
            rootBucket={process.env.REACT_APP_S3_BUCKET_NAME_DEV}
            files={visa?.attach_files}
            canDelete={isEditData ? true : false}
            isBox={true}
            callback={attachFileViewerCallback}
          />
        )}
      </Box>
    </Box>

  );
};

export default TestDetail;
