import { useEffect, useState, useRef } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import "./App.css";

import * as AuthMain from "./auth/AuthMain";
import { userState } from "./interface/MainInterface";
import MainConteiner from "./mainContainer/MainConteiner";
import LeftMenu from "./mainContainer/LeftMenu";

let userParam: userState = {
  id: "",
  name: "",
  company: "",
  isAuth: false,
  isAdmin: false, //관리자
  isSuperAdmin: false, //결재자 (팀장급)
  isTeamTester: false,
  imageUrl: "", //사용자 프로필 사진
};

const theme = createTheme({
  typography: {
    fontFamily: "Spoqa, 맑은 고딕, Malgun Gothic, sans-serif",
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: "inherit",
          fontSize: "inherit",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#1D81A5",
      contrastText: "#fff",
    },
    error: {
      main: "#ff0000",
      contrastText: "#fff",
    },
  },
  zIndex: { drawer: 990, modal: 990 },
});

function App() {
  const headerRef: any = useRef();
  const [userState, setUserState] = useState<userState>();
  const [selectedMenu, setSelectedMenu] = useState("");
  const [leftUpdate, setLeftUpdate] = useState(false);
  const [isSession, setIsSession] = useState(false);

  async function setUserInfo() {
    const result = AuthMain.getSession();
    if (result !== null) {
      userParam.id = result.id;
      if (result.teamType === 1) {
        userParam.isTeamTester = true;
      }
      userParam.name = result.name; //이름이 들어가야 함
      userParam.company = result.company;
      userParam.isAuth = true;
      userParam.imageUrl = result.imageUrl;
      if (result.userType === 1) {
        // 결재 관리자
        userParam.isAdmin = true;
        userParam.isSuperAdmin = true;
      } else if (result.userType === 2) {
        //일반 관리자
        userParam.isAdmin = true;
        userParam.isSuperAdmin = false;
      } else {
        //일반 사용자
        userParam.isAdmin = false;
        userParam.isSuperAdmin = false;
      }
      setIsSession(true);
    }
    // console.log("userParam: ", userParam);
    setUserState(userParam);
  }

  useEffect(() => {
    setUserInfo();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {userState !== undefined && (
          <BrowserRouter>
            <div id="wrap">
              {isSession ? (
                <LeftMenu userState={userState} selectedMenu={selectedMenu} setLeftUpdate={setLeftUpdate} leftUpdate={leftUpdate} />
              ) : (
                <></>
              )}
              <MainConteiner userState={userState} setSelectedMenu={setSelectedMenu} setLeftUpdate={setLeftUpdate} />
            </div>
          </BrowserRouter>
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
