import React, { useEffect, useState, useRef } from "react";
// import { useNavigate } from "react-router-dom";
import { AES } from "crypto-js";

// import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
// import Paper from "@mui/material/Paper";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Toast from "../../utils/Toast";
import { HttpCustomApi } from "../../interface/custom-api";
import { appConfig } from "../../config/Config";
import * as AuthMain from "../../auth/AuthMain";

import "./Login.css";
const visaApi = new HttpCustomApi(
  window.location.hostname === appConfig.prodURL
    ? appConfig.REACT_APP_VISA_API_PROD
    : appConfig.REACT_APP_VISA_API_DEV
);

interface propsType {}

// const style = {
//   position: "absolute" as "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "100%",
//   p: 1,
// };

const Login = (props: propsType) => {
  // let navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [rememberId, setRememderId] = useState(false);
  const toastRef: any = useRef();

  const handlePasswordEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") fncProcLogin();
  };

  // 로그인 처리
  const fncProcLogin = async () => {
    if (userId === "" || userId.trim() === "") {
      toastRef.current?.toast("사번을 입력하세요.", "warning", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    const param: any = {
      command: "login_proc",
      pk: "현대드림투어",
      sk: userId,
    };

    const res = await visaApi.common_request("adminMain", param);
    if ("" + res.code === "200") {
      if (res.response.result === "success") procSesson(res.response.session);
      else procFail(res.response);
    } else {
      toastRef.current?.toast(res.response.result_msg, "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
    }

    // if (userId=='rosijin' || userId=='mh' || userId=='kmc'|| userId=='poc01' || userId=='poc02'|| userId=='poc03' 
    // || userId=='poc04'|| userId=='poc05' || userId=='poc06'|| userId=='poc07' || userId=='poc08') {
    //   procSesson(userId);
    // }
    // else {
    //   toastRef.current?.toast("Please enter your id.", "error", 4000, { vertical: "top", horizontal: "center" });
    //   // userEmailRef.current.focus();
    //   return;
    // }
  };

  // 로그인 성공
  const procSesson = (sessionData: any) => {
    toastRef.current?.toast("Welcome. Enjoy 드림투어AI", "success", 4000, { vertical: "top", horizontal: "center" });
    const sessionObj: any = {
      id: sessionData.id,
      userType: sessionData.user_type,
      imageUrl: sessionData.image_url,
      name: sessionData.user_name,
      company: sessionData.user_info1,
      teamType: sessionData.team_type,
      expire: Date.now() + 1000 * 60 * 60 * 24, // 24시간
    };
    const objString = JSON.stringify(sessionObj);
    const encObjString = AES.encrypt(objString, appConfig.seed).toString();
    window.localStorage.setItem(appConfig.sessionName, encObjString);

    // 아이디 저장 기억하기
    const rememberIdObj: any = {
      id: sessionData.id,
      userType: sessionData.user_type,
      imageUrl: sessionData.image_url,
      name: sessionData.user_name,
      company: sessionData.user_info1,
      teamType: sessionData.team_type,
      remember: "TRUE",
    };
    const objRememberId = JSON.stringify(rememberIdObj);
    if (rememberId) {
      window.localStorage.setItem(appConfig.rememberidSSName, objRememberId);
    }
    window.location.href = "/";
  };

  useEffect(() => {
    if (!rememberId && checkRememberId) {
      window.localStorage.removeItem(appConfig.rememberidSSName);
    }
  }, [rememberId]);

  // 로그인 실패
  const procFail = (res: any) => {
    toastRef.current?.toast(res.result_msg, "error", 3000, {
      vertical: "top",
      horizontal: "center",
    });
  };

  const handleRememberIdCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRememderId(event.target.checked);
  };

  const [checkRememberId, setCheckRememberId] = useState(false);
  const [needLogin, setNeedLogin] = useState(true);
  useEffect(() => {
    const result = AuthMain.getSession();
    if (result !== null) {
      setNeedLogin(false);
      window.location.href = "/";
    } else {
      setNeedLogin(true);
    }
    const objString = window.localStorage.getItem(appConfig.rememberidSSName);
    if (objString !== null) {
      const obj = JSON.parse(objString);
      if (obj.remember === "TRUE") {
        setRememderId(true);
        setUserId(obj.id);
        setCheckRememberId(true);
        // pwdRef.current.focus();
      }
    }
  }, []);

  return (
    <>
      {needLogin ? (
        <div className="login-root">
          <div className="login-wrapper">
            <div className="login-box">
              <h2>
                <img src="https://img.hyundaidreamtour.com/images/aihdt/loading_ai.svg" alt="logo" />
              </h2>
              <Stack spacing={2} direction="column"
                sx={{
                  marginTop: "50px",
                }}
              >
                <div className="TextField-without-border-radius">
                  <TextField
                    value={userId}
                    placeholder="아이디를 입력해주세요."
                    variant="outlined"
                    autoFocus
                    fullWidth
                    autoComplete="off"
                    type="search"
                    inputProps={{ enterKeyHint: "enter",
                        style: { padding: "12px 16px 10px 16px",
                        borderRadius: "8px",
                        fontFamily: "none",
                        border: "none",
                      }
                    }}
                    sx={{ maxWidth: "400px" }}
                    onChange={(e) => {
                      setUserId(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        fncProcLogin();
                      }
                    }}
                  />
                </div>
                {/* <div className="TextField-without-border-radius">
                  <TextField
                    inputRef={pwdRef}
                    value={userPwd}
                    placeholder="패스워드를 입력해주세요."
                    variant="outlined"
                    type="password"
                    autoComplete="current-password"
                    fullWidth
                    sx={{ maxWidth: "400px" }}
                    onChange={(e) => {
                      setUserPwd(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      handlePasswordEnter(e);
                    }}
                  />
                </div> */}
                <div>
                  <FormGroup sx={{ display: "block" }}>
                    <FormControlLabel
                      control={<Checkbox id="id_check" name="id_check" checked={rememberId} onChange={handleRememberIdCheck} />}
                      label="아이디 저장"
                      sx={{ width: "100%", maxWidth: "380px" }}
                    />
                  </FormGroup>
                </div>
                <div>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      maxWidth: "400px",
                      borderRadius: "25px",
                      height: "52px",
                      mt: 3,
                    }}
                    color="primary"
                    onClick={() => {
                      fncProcLogin();
                    }}
                  >
                    로그인
                  </Button>
                </div>
                {/* <div>
                  <Button
                    variant="text"
                    onClick={() => {
                      fncChangePwd();
                    }}
                  >
                    패스워드변경
                  </Button>
                </div> */}
              </Stack>
            </div>
            <div className="copy-warapper">
              <p className="copyright">© HYUNDAI DREAM TOUR All Right Reserved.</p>
            </div>
          </div>
          <Toast ref={toastRef} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Login;
